<template>
  <div>
    <div class="header">
      <div
        @click="logout"
        class="btn-img">
        <img src="@/assets/icon-singout.png"/>
      </div>
    </div>
    <div class="title">■View Results</div>
    <div class="menu-row">
      <div
        @click="link('steps?redirectTo=lessonResults')">
        <img src="@/assets/menu-lessons.png"/>
      </div>
      <div
        @click="link('steps?redirectTo=trialResults')">
        <img src="@/assets/menu-trial.png"/>
      </div>
      <div
        @click="link('report')">
        <img src="@/assets/menu-report.png"/>
      </div>
    </div>
    <div class="title">■Setting</div>
    <div class="menu-row">
      <div
        @click="link('steps?redirectTo=timitLimitConfig')"
        class="px-4">
        <img src="@/assets/menu-timelimit.png"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Term',
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    link(name) {
      this.$router.push(name)
    },
    logout() {
      this.$store.commit('clearAuthInfo')
      this.$router.push('/manager/login')
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.title {
  font-size: 30px;
  text-align: left;
  padding-left: 50px;
}
.config {
  flex: 2;
  width: 60px;
  cursor: pointer;
  right: 0;
  display: block;
  margin-top: 50px;
  img {
    width: 60px;
  }
}
.message {
  height: 30px;
}
.menu-row {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  img {
    width: 300px;
    cursor: pointer;
  }
}
</style>

